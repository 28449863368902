<template>
  <div>
    <div class="container-header">
			<h3>{{ title }}</h3>
		</div>
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="6">
            <div class="form-group mb-0">
              <div class="input-eye">
                <p class="tag-eye"><EyeFill /> D</p>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  :value="items?.olhoDireito"
                  :readonly="true"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="form-group mb-0">
              <div class="input-eye">
                <p class="tag-eye"><EyeFill /> E</p>
                <input
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  :value="items?.olhoEsquerdo"
                  :readonly="true"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-2" v-if="getExtraTableKeys().length">
      <b-col>
        <table class="additional-exam-table disabled">
          <thead>
            <tr>
              <th></th>
              <th 
                v-for="(key, index) in getExtraTableKeys()"
                :key="index"
              >
                {{ key }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><EyeFill />D</td>
              <td 
                v-for="(key, index) in getExtraTableKeys()"
                :key="index"
              >
                {{ items[`olhoDireito${key}`] }}
              </td>
            </tr>
            <tr>
              <td><EyeFill />E</td>
              <td 
                v-for="(key, index) in getExtraTableKeys()"
                :key="index"
              >
                {{ items[`olhoEsquerdo${key}`] }}
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-row class="mt-2" v-if="getExtraDescriptiveKeys().length">
      <b-col>
        <div class="form-group" v-for="(key, index) in getExtraDescriptiveKeys()" :key="index">
          <label>
            {{ key.charAt(0).toUpperCase() + key.slice(1) }}</label>
            <TextArea
              :id="`extra-${key}`"
              :value="items[key]"
              :readonly="true"
              rows="1"
            />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  props: {
    items: Object,
    title: String,
    },
  components: {
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    TextArea: () => import('@/components/General/TextArea'),
  },
  computed: {
    customFormName() {
      return this.items?.customName
    }
  },
  methods: {
    getExtraTableKeys() {
      return [...new Set(
        Object.keys(this.items)
        .filter(k => !['olhoDireito', 'olhoEsquerdo', 'customName'].includes(k) && k.includes('olho'))
        .map(field => field.replace(/olho(Direito|Esquerdo)/, '')))];
    },
    getExtraDescriptiveKeys() {
      return [...new Set(
        Object.keys(this.items)
        .filter(k => !['olhoDireito', 'olhoEsquerdo', 'customName'].includes(k) && !k.includes('olho')))];
    },
  }
}
</script>

<style lang="scss" scoped>
.additional-exam-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  text-align: center;

  th {
    font-size: 12px;
    line-height: 16px;
    color: var(--type-active);
  }

  td {
    border: 1px solid var(--neutral-300);
    padding: 6px 12px;
  }

  th:first-child {
    width: 60px;
  }

  td:first-child svg {
    margin-bottom: 2px;
  }

  tr:first-child td {
    border-bottom: none;
    background-color: var(--neutral-100);
  }

  tr :not(td:last-child) {
    border-right: none;
  }

  tr:first-child td:first-child {
    border-top-left-radius: 8px;
  }
  tr:first-child td:last-child {
    border-top-right-radius: 8px;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
    background-color: var(--neutral-100);
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }

  td:not(:first-child) {
    font-size: 14px;
  }

  td:first-child {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    svg {
      width: 16px;
      height: 16px;
      fill: var(--type-active);
      margin-right: 4px;
    }
  }
}

.disabled {
  td:not(:first-child) {
    background-color: var(--neutral-100);
    color: var(--type-placeholder);
  }
}

.form-control {
  font-size:14px;
}

.container-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-right: 30px;

		h3 {
			font-size: 18px;
			color: var(--dark-blue);
			font-weight: 600;
		}

	}
</style>
